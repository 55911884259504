/* cspell:disable */
import CurrencyCode from 'types/consts/currencies/currency-code.const'

/**
 * {@link https://www.ecb.europa.eu/stats/eurofxref/eurofxref-daily.xml}
 */
const CurrencyName = {
    [CurrencyCode.AUD]: 'Australian Dollar',
    [CurrencyCode.BGN]: 'Bulgarian Lev',
    [CurrencyCode.BRL]: 'Brazilian Real',
    [CurrencyCode.CAD]: 'Canadian Dollar',
    [CurrencyCode.CHF]: 'Swiss Franc',
    [CurrencyCode.CNY]: 'Chinese Yuan',
    [CurrencyCode.CZK]: 'Czech Koruna',
    [CurrencyCode.DKK]: 'Danish Krone',
    [CurrencyCode.EUR]: 'Euro',
    [CurrencyCode.GBP]: 'British Pound',
    [CurrencyCode.HKD]: 'Hong Kong Dollar',
    [CurrencyCode.HRK]: 'Croatian Kuna',
    [CurrencyCode.HUF]: 'Hungarian Forint',
    [CurrencyCode.IDR]: 'Indonesian Tupiah',
    [CurrencyCode.ILS]: 'Israeli New Shekel',
    [CurrencyCode.INR]: 'Indian Rupee',
    [CurrencyCode.ISK]: 'Icelandic Króna',
    [CurrencyCode.JPY]: 'Japanese Yen',
    [CurrencyCode.KRW]: 'South Korean Wow',
    [CurrencyCode.MXN]: 'Mexican Peso',
    [CurrencyCode.MYR]: 'Malaysian Ringgit',
    [CurrencyCode.NOK]: 'Norwegian Krone',
    [CurrencyCode.NZD]: 'New Zealand Dollar',
    [CurrencyCode.PHP]: 'Philippine Peso',
    [CurrencyCode.PLN]: 'Polish Złoty',
    [CurrencyCode.RON]: 'Romanian Leu',
    [CurrencyCode.RUB]: 'Russian Ruble',
    [CurrencyCode.SEK]: 'Swedish Krona',
    [CurrencyCode.SGD]: 'Singapore Dollar',
    [CurrencyCode.THB]: 'Thai Baht',
    [CurrencyCode.TRY]: 'Turkish Lira',
    [CurrencyCode.USD]: 'United States Dollar',
    [CurrencyCode.ZAR]: 'South African Rand',
} as const

export default CurrencyName
