import { searchItemsSearchDefaultValues, type SearchItemsSearch } from 'pages/_app/app.router'
import { SEARCH_TEXT, SEARCH_IS_NEW, SEARCH_IS_NEW_PRICE, SEARCH_IS_FAVORITE } from 'types/others'
import type { ScraperAll } from 'types/types/scraper.type'

/**
 * GetSearchQuery
 * @param scraper scraper
 * @returns SearchQuery
 */
export default function getSearchQuery(scraper?: ScraperAll): SearchItemsSearch {
    return {
        [SEARCH_TEXT]: scraper?.filtersPre?.text ?? searchItemsSearchDefaultValues[SEARCH_TEXT],
        [SEARCH_IS_NEW]: scraper?.filtersPre?.isNew ?? searchItemsSearchDefaultValues[SEARCH_IS_NEW],
        [SEARCH_IS_NEW_PRICE]: scraper?.filtersPre?.isNewPrice ?? searchItemsSearchDefaultValues[SEARCH_IS_NEW_PRICE],
        [SEARCH_IS_FAVORITE]: scraper?.filtersPre?.isFavorite ?? searchItemsSearchDefaultValues[SEARCH_IS_FAVORITE],
    }
}
