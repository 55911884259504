import { createWithEqualityFn as create } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import { checkStandalone, type PwaInfosType } from 'hooks/pwa.hook'

export interface PwaState extends PwaInfosType {
    /** Is app updating */
    isUpdating: boolean
    /** Set pwa */
    setInformations: (pwaInfos: PwaInfosType) => void
    /** Set pwa */
    setIsUpdating: (isUpdating: PwaState['isUpdating']) => void
}

const usePwaStore = create<PwaState>()(
    set => ({
        isInstallPromptSupported: false,
        promptInstall: () => Promise.resolve(false),
        isStandalone: checkStandalone(),
        isUpdating: false,
        setInformations: ({ isInstallPromptSupported, promptInstall, isStandalone }) => {
            set({ isInstallPromptSupported, promptInstall, isStandalone })
        },
        setIsUpdating: isUpdating => {
            set({ isUpdating })
        },
    }),
    shallow,
)

export default usePwaStore
