import { useMemo } from 'react'
import { useKeyPressEvent } from 'react-use'
import { getRouteApi } from '@tanstack/react-router'
import { PENDING } from 'types/status'
import useUserStore from 'stores/user.store'
import type { ElementRef } from 'react'
import type { SearchidSearchesContextType } from 'pages/searches/[searchid]/index/index.context'
import type { Status } from 'types/status'
import type { UseIndexNavigationReturns } from 'pages/searches/[searchid]/index/hooks/navigation.hook'
import type { SearchType } from 'requests/types/helpers'

/**
 * Check if keyboard can you use to change scraper/search depending on what is currently focused
 * @returns isKeyboardInteractionAllowed
 */
const isKeyboardInteractionAllowed = () =>
    !['input'].includes(document.activeElement?.nodeName.toLowerCase() ?? '') &&
    !['checkbox'].includes((document.activeElement as ElementRef<'input'>).type)

export interface UseIndexKeyboardParams
    extends Pick<SearchidSearchesContextType, 'fetchItems' | 'currentScraperIndex' | 'searchUser'>,
        Pick<UseIndexNavigationReturns, 'goToNext' | 'goToPrev'> {
    /** Status */
    status: Status
    /** SearchId */
    searchId: SearchType['_id']
}

const DEFAULT_SEARCHES = [] as Array<SearchType>

const route = getRouteApi('/layout/searches/$searchid')

/**
 * UseIndexKeyboard to handle keyboard navigation
 */
export default function useIndexKeyboard({
    goToNext,
    goToPrev,
    currentScraperIndex,
    status,
    fetchItems,
    searchId,
}: UseIndexKeyboardParams) {
    const navigate = route.useNavigate()

    /** Searches enable for the current user */
    const searchesEnable = useUserStore(state => state.me?.searches.filter(search => search.isEnable) ?? DEFAULT_SEARCHES)

    /** Current search index in list of enable search user */
    const currentSearchIndex = useMemo(() => searchesEnable.findIndex(search => searchId === search._id), [searchId, searchesEnable])

    // On keyup
    useKeyPressEvent('ArrowUp', () => {
        if (currentScraperIndex > -1) {
            if (isKeyboardInteractionAllowed() && document.scrollingElement?.scrollTop === 0 && status !== PENDING) {
                void fetchItems()
            }
        } else {
            const newIndex = currentSearchIndex - 1

            if (isKeyboardInteractionAllowed() && newIndex >= 0) {
                void navigate({ params: { searchid: searchesEnable[newIndex]?._id ?? '' } })
            }
        }
    })

    // On keydown
    useKeyPressEvent('ArrowDown', () => {
        if (currentScraperIndex > -1) {
            return
        }

        const newIndex = currentSearchIndex + 1

        if (isKeyboardInteractionAllowed() && newIndex <= (searchesEnable.length || 0) - 1) {
            void navigate({ params: { searchid: searchesEnable[newIndex]?._id ?? '' } })
        }
    })

    // On key left
    useKeyPressEvent('ArrowLeft', ev => {
        if (isKeyboardInteractionAllowed()) {
            ev.preventDefault()
            goToPrev()
        }
    })

    // On key right
    useKeyPressEvent('ArrowRight', ev => {
        if (isKeyboardInteractionAllowed()) {
            ev.preventDefault()
            goToNext()
        }
    })
}
