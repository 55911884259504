import { createWithEqualityFn as create } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import getUuid from 'utils/others/get-uuid'
import type { AlertProps } from 'components/containers/alert/alert.component'
import type { LinkProps } from '@tanstack/react-router'

export interface NavigationState {
    /** Current page title */
    pageTitle: Array<string>
    /** Page to go back */
    backHistory: LinkProps
    /** Alert used to display information in app */
    alert: {
        /** Key */
        key?: string
        /** Content */
        content?: AlertProps['children']
        /** Type */
        type?: AlertProps['type']
        /** Timeout in ms */
        timeout?: number
        /** Should the the alert be persisted ONCE across navigation. Default to `false` */
        persist?: boolean
    } | null
    /** SetNavigation */
    setNavigation: ({
        title,
        backTo,
    }: {
        /** Title */
        title: Array<string> | string
        /** BackTo */
        backTo: LinkProps
    }) => void
    /** SetAlert */
    setAlert: (alert: NavigationState['alert']) => void
}

const useNavigationStore = create<NavigationState>()(
    set => ({
        pageTitle: [],
        backHistory: { to: '/' },
        alert: null,
        setNavigation: ({ title, backTo }) => {
            set({
                pageTitle: Array.isArray(title) ? title : [title],
                backHistory: backTo,
            })
        },
        setAlert: alert => {
            set(() => {
                // Assign a uuid to the key if there's an alert but no key was sent
                if (alert && !alert.key) {
                    return { alert: { ...alert, key: getUuid() } }
                }

                return { alert }
            })
        },
    }),
    shallow,
)

export default useNavigationStore
