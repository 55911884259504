/**
 * Timer
 * @param cb cb
 * @param delay delay
 * {@link https://stackoverflow.com/questions/3969475/javascript-pause-settimeout}
 * @returns Timer
 */
export default function timer(cb: () => void, delay: number) {
    let timerId: NodeJS.Timeout | undefined
    let start: Date
    let remaining = delay

    const pause = () => {
        clearTimeout(timerId)
        timerId = undefined
        remaining -= new Date().getTime() - start.getTime()
    }

    const play = () => {
        if (timerId) {
            return
        }

        start = new Date()
        timerId = setTimeout(cb, remaining)
    }

    const stop = () => {
        clearTimeout(timerId)
        timerId = undefined
        remaining = 0
    }

    play()

    return {
        pause,
        play,
        stop,
    }
}
