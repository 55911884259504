const env = {
    /** Base key */
    BASE_STORAGE_KEY: import.meta.env.VITE_BASE_STORAGE_KEY || 'app',
    /** Vapid key used for notifications */
    VAPID_KEY: import.meta.env.VITE_VAPID_KEY,
    /** Node Env */
    MODE: import.meta.env.MODE,
    /** Public Url */
    BASE_URL: import.meta.env.BASE_URL,
    /** Locale */
    LOCALE: import.meta.env.VITE_LOCALE || 'en-GB',
}

export default env
