/**
 * Is html?
 * @param str String
 * @returns IsHtml
 */
export default function isHtml(str?: string): boolean {
    const fragment = document.createRange().createContextualFragment(str ?? '')

    // Remove all non text nodes from fragment
    fragment.querySelectorAll('*').forEach(el => el.parentNode?.removeChild(el))

    // If there is textContent, then not a pure HTML
    return !(fragment.textContent ?? '').trim()
}
