/* eslint-disable @typescript-eslint/unbound-method */
/**
 * Set native value
 * {@link https://stackoverflow.com/a/53797269/9678258}
 * @param element Element
 * @param value Value
 */
export default function setNativeValue(element: HTMLElement | null, value: string) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const prototype = Object.getPrototypeOf(element)
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter?.call(element, value)
    } else {
        valueSetter?.call(element, value)
    }
}
