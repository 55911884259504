/* eslint-disable @typescript-eslint/no-non-null-assertion */
import client from 'requests/client'
import type { GetMutationOptions, GetQueryOptions } from 'requests/types/common'

/**
 * Search queries
 */
export const searchesQueries: {
    /** Upsert */
    upsert: GetMutationOptions<'PUT', '/api/searches/{searchId}'>
    /** Remove */
    remove: GetMutationOptions<'DELETE', '/api/searches/{searchId}'>
    /** Sort */
    sort: GetMutationOptions<'PATCH', '/api/searches/search-sort'>
} = {
    upsert: () => ({
        mutationFn: async param =>
            (
                await (param.params?.path?.searchId // eslint-disable-line @typescript-eslint/no-unnecessary-condition
                    ? client.PUT('/api/searches/{searchId}', param)
                    : client.POST('/api/searches', param as never))
            ).data!,
    }),
    remove: () => ({
        mutationFn: async param => (await client.DELETE('/api/searches/{searchId}', param)).data!,
    }),
    sort: () => ({
        mutationFn: async param => (await client.PATCH('/api/searches/search-sort', param)).data!,
    }),
}

/**
 * Scrapers queries
 */
export const scrapersQueries: {
    /** Update */
    update: GetMutationOptions<'PUT', '/api/searches/{searchId}/scrapers/{scraperValue}'>
} = {
    update: () => ({
        mutationFn: async param => (await client.PUT('/api/searches/{searchId}/scrapers/{scraperValue}', param)).data!,
    }),
}

/**
 * Items queries
 */
export const itemsQueries: {
    /** GetAll */
    getAll: GetQueryOptions<'GET', '/api/searches/{searchId}/scrapers/{scraperValue}/items'>
    /** UpdateHistory */
    updateHistory: GetMutationOptions<'PATCH', '/api/searches/{searchId}/scrapers/{scraperValue}/histories'>
    /** AddFavorites */
    addFavorites: GetMutationOptions<'PATCH', '/api/searches/{searchId}/scrapers/{scraperValue}/favorites'>
    /** RemoveFavorites */
    removeFavorites: GetMutationOptions<'DELETE', '/api/searches/{searchId}/scrapers/{scraperValue}/favorites'>
} = {
    getAll: ({ searchId, scraperValue }) => ({
        queryKey: ['searches', searchId, 'scrapers', scraperValue, 'items'],
        queryFn: async ({ signal }) =>
            (
                await client.GET('/api/searches/{searchId}/scrapers/{scraperValue}/items', {
                    params: { path: { scraperValue, searchId } },
                    signal,
                })
            ).data!,
    }),
    updateHistory: () => ({
        mutationFn: async param => (await client.PATCH('/api/searches/{searchId}/scrapers/{scraperValue}/histories', param)).data!,
    }),
    addFavorites: () => ({
        mutationFn: async param => (await client.PATCH('/api/searches/{searchId}/scrapers/{scraperValue}/favorites', param)).data!,
    }),
    removeFavorites: () => ({
        mutationFn: async param => (await client.DELETE('/api/searches/{searchId}/scrapers/{scraperValue}/favorites', param)).data!,
    }),
}

/**
 * Users queries
 */
export const usersQueries: {
    /** UpdateMe */
    updateMe: GetMutationOptions<'PATCH', '/api/users/me'>
    /** GetMe */
    getMe: GetQueryOptions<'GET', '/api/users/me'>
    /** GetMeLastUpdate */
    getMeLastUpdate: GetQueryOptions<'GET', '/api/users/me/lastupdate'>
    /** Login */
    login: GetMutationOptions<'POST', '/api/users/authentication/login'>
    /** Logout */
    logout: GetMutationOptions<'POST', '/api/users/authentication/logout'>
    /** Refresh */
    refresh: GetMutationOptions<'PATCH', '/api/users/authentication/refresh'>
} = {
    updateMe: () => ({
        mutationFn: async param => (await client.PATCH('/api/users/me', param)).data!,
    }),
    getMe: () => ({
        queryKey: ['users', 'me'],
        queryFn: async ({ signal }) => (await client.GET('/api/users/me', { signal })).data!,
    }),
    getMeLastUpdate: () => ({
        queryKey: ['users', 'me', 'lastupdate'],
        queryFn: async ({ signal }) => (await client.GET('/api/users/me/lastupdate', { signal })).data!,
    }),
    login: () => ({
        mutationFn: async param => (await client.POST('/api/users/authentication/login', param)).data!,
    }),
    logout: () => ({
        mutationFn: async param => (await client.POST('/api/users/authentication/logout', param)).data!,
    }),
    refresh: () => ({
        mutationFn: async param => (await client.PATCH('/api/users/authentication/refresh', param)).data!,
    }),
}

/**
 * Users queries
 */
export const webAuthnQueries: {
    /** GetRegisterOption */
    getRegisterOption: GetQueryOptions<'GET', '/api/webauthns/register/options'>
    /** Register */
    register: GetMutationOptions<'POST', '/api/webauthns/register'>
    /** GetAuthenticateOptions */
    getAuthenticateOptions: GetQueryOptions<'GET', '/api/webauthns/authenticate/options'>
    /** Authenticate */
    authenticate: GetMutationOptions<'POST', '/api/webauthns/authenticate'>
} = {
    getRegisterOption: () => ({
        queryKey: ['webauthns', 'register', 'options'],
        queryFn: async ({ signal }) => (await client.GET('/api/webauthns/register/options', { signal })).data!,
    }),
    register: () => ({
        mutationFn: async param => (await client.POST('/api/webauthns/register', param)).data!,
    }),
    getAuthenticateOptions: () => ({
        queryKey: ['webauthns', 'authenticate', 'options'],
        queryFn: async ({ signal }) => (await client.GET('/api/webauthns/authenticate/options', { signal })).data!,
    }),
    authenticate: () => ({
        mutationFn: async param => (await client.POST('/api/webauthns/authenticate', param)).data!,
    }),
}
