/**
 * Get uuid v4
 * {@link https://stackoverflow.com/a/2117523/9678258}
 * @returns Uuid
 */
export default function getUuid() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0]! & (15 >> (+c / 4)))).toString(16),
    )
}
