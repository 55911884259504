import env from 'types/env'

/** Storage user key */
export const STORAGE_USER_KEY = `${env.BASE_STORAGE_KEY}_user`
/** Storage updated at key */
export const STORAGE_UPDATED_AT_KEY = `${env.BASE_STORAGE_KEY}_updated_at`
/** Broadcast channel key */
export const BROADCAST_CHANNEL_KEY = `${env.BASE_STORAGE_KEY}_broadcast_channel`
/** Cookie to show/hide browser dialog */
export const COOKIE_BROWSER_DIALOG_KEY = `${env.BASE_STORAGE_KEY}_cookie_browser_dialog`

/** Broadcast sw update */
export const BROADCAST_SW_UPDATE = 'broadcast_sw_update'
/** Broadcast error on fetch item */
export const BROADCAST_FETCH_FAILED = 'broadcast_fetch_failed'

/** New item URL Id */
export const NEW = 'new'

/** Search param text */
export const SEARCH_TEXT = 'text'
/** Search param is new */
export const SEARCH_IS_NEW = 'isNew'
/** Search param is new price */
export const SEARCH_IS_NEW_PRICE = 'isNewPrice'
/** Search param is favorite */
export const SEARCH_IS_FAVORITE = 'isFavorite'

/** Skip waiting for sw */
export const SKIP_WAITING = 'SKIP_WAITING'
