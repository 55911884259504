const SearchIcon = {
    BOOK: 'book',
    GAME: 'game',
    MOVIE: 'movie',
    MUSIC: 'music',
    OTHER: 'other',
} as const

export type SearchIconType = typeof SearchIcon
export type SearchIconKeys = keyof SearchIconType
export type SearchIconValues = SearchIconType[SearchIconKeys]

export default SearchIcon
