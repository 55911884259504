const Scraper = {
    BRAVADO: 'bravado',
    BRAVADO_CLAYMAN: 'bravadoclayman',
    CDANDLP: 'cdandlp',
    DEPOP: 'depop',
    DISCOGS: 'discogs',
    DISCOGS_WANTLIST: 'discogswantlist',
    EBAY: 'ebay',
    EMP: 'emp',
    FINN: 'finn',
    INFLAMESSHOP: 'inflamesshop',
    LEBONCOIN: 'leboncoin',
    MOMOX: 'momox',
    MUSICSTACK: 'musicstack',
    NUCLEARBLAST: 'nuclearblast',
    RECORDCITY: 'recordcity',
    TRADERA: 'tradera',
    VINTED: 'vinted',
    MAIL: 'mail',
    LINK: 'link',
} as const

export type ScraperType = typeof Scraper
export type ScraperKeys = keyof ScraperType
export type ScraperValues = ScraperType[ScraperKeys]

export default Scraper
