/* eslint-disable no-console */
import client from 'requests/client'
import env from 'types/env'

/**
 * Get the subscription to use
 * @param isUpsert Create a new one if none exist and permission granted. Default to `true`
 * @returns PushSubscription
 */
export async function getSubscription(isUpsert = true): Promise<PushSubscription | null> {
    /** Check if sw is available */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!navigator.serviceWorker) {
        return null
    }

    const { pushManager } = await navigator.serviceWorker.ready

    const subscription = await pushManager.getSubscription()

    if (subscription !== null) {
        return subscription
    }

    if (Notification.permission === 'granted' && isUpsert) {
        return pushManager.subscribe({
            applicationServerKey: env.VAPID_KEY,
            userVisibleOnly: true,
        })
    }

    return null
}

/**
 * Subscribe an user
 * @param param param
 * @param param.pushSubscription PushSubscription
 * @returns PushSubscription
 */
export async function subscribe({
    pushSubscription,
}: {
    /** PushSubscription */
    pushSubscription?: PushSubscription | null
} = {}): Promise<PushSubscription | null> {
    try {
        const subscription = pushSubscription ?? (await getSubscription())

        if (!subscription) {
            return null
        }

        await client.POST('/api/subscriptions', {
            body: {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                subscriptions: {
                    ...JSON.parse(JSON.stringify(subscription)),
                    userAgent: navigator.userAgent,
                },
            },
        })

        return subscription
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * Unsubscribe an user
 * @returns PushSubscription
 */
export async function unsubscribe({
    pushSubscription,
}: {
    /** PushSubscription */
    pushSubscription?: PushSubscription | null
} = {}): Promise<PushSubscription | null> {
    try {
        const subscription = pushSubscription ?? (await getSubscription())

        if (!subscription) {
            return null
        }

        await client.DELETE('/api/subscriptions', {
            body: {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                subscriptions: {
                    ...JSON.parse(JSON.stringify(subscription)),
                    userAgent: navigator.userAgent,
                },
            },
        })

        return subscription
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * Ask for notification permission
 */
export async function askForNotificationPermission({
    onDeniedAfterAsk,
    onAlreadyDenied,
    onGrantedAfterAsk,
    onAlreadyGranted,
}: {
    /** OnDeniedAfterAsk */
    onDeniedAfterAsk?: () => void
    /** OnAlreadyDenied */
    onAlreadyDenied?: () => void
    /** OnGrantedAfterAsk */
    onGrantedAfterAsk?: () => void
    /** OnAlreadyGranted */
    onAlreadyGranted?: () => void
} = {}): Promise<void> {
    /** Check if sw is available */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!navigator.serviceWorker) {
        return
    }

    await navigator.serviceWorker.ready

    // eslint-disable-next-line compat/compat
    switch (Notification.permission) {
        case 'default': {
            // eslint-disable-next-line compat/compat
            const notificationPermission = await Notification.requestPermission()
            switch (notificationPermission) {
                case 'denied':
                    onDeniedAfterAsk?.()
                    break
                case 'granted':
                    onGrantedAfterAsk?.()
                    break
                case 'default':
                default:
                    break
            }
            break
        }
        case 'denied':
            onAlreadyDenied?.()
            break
        case 'granted':
            onAlreadyGranted?.()
            break
        default:
            break
    }
}
