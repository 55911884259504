import CurrencyCode from 'types/consts/currencies/currency-code.const'

/**
 * {@link https://www.ecb.europa.eu/stats/eurofxref/eurofxref-daily.xml}
 * {@link https://gist.github.com/nhalstead/4c1652563dd13357ab936fc97703c019#file-currency-symbols-json}
 */
const CurrencySymbol = {
    [CurrencyCode.AUD]: '$',
    [CurrencyCode.BGN]: 'лв',
    [CurrencyCode.BRL]: 'R$',
    [CurrencyCode.CAD]: '$',
    [CurrencyCode.CHF]: 'CHF',
    [CurrencyCode.CNY]: '¥',
    [CurrencyCode.CZK]: 'Kč',
    [CurrencyCode.DKK]: 'kr',
    [CurrencyCode.EUR]: '€',
    [CurrencyCode.GBP]: '£',
    [CurrencyCode.HKD]: '$',
    [CurrencyCode.HRK]: 'kn',
    [CurrencyCode.HUF]: 'Ft',
    [CurrencyCode.IDR]: 'Rp',
    [CurrencyCode.ILS]: '₪',
    [CurrencyCode.INR]: '₹',
    [CurrencyCode.ISK]: 'kr',
    [CurrencyCode.JPY]: '¥',
    [CurrencyCode.KRW]: '₩',
    [CurrencyCode.MXN]: '$',
    [CurrencyCode.MYR]: 'RM',
    [CurrencyCode.NOK]: 'kr',
    [CurrencyCode.NZD]: '$',
    [CurrencyCode.PHP]: '₱',
    [CurrencyCode.PLN]: 'zł',
    [CurrencyCode.RON]: 'lei',
    [CurrencyCode.RUB]: 'руб',
    [CurrencyCode.SEK]: 'kr',
    [CurrencyCode.SGD]: '$',
    [CurrencyCode.THB]: '฿',
    [CurrencyCode.TRY]: '₺',
    [CurrencyCode.USD]: '$',
    [CurrencyCode.ZAR]: 'R',
} as const

export default CurrencySymbol
