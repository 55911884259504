import { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useKeyPressEvent } from 'react-use'
import useListener from 'hooks/listener.hook'
import type { ComponentProps, ElementRef, ForwardedRef, RefObject } from 'react'
import type { DialogProps } from 'components/containers/dialog/dialog.component'
import type { ButtonProps } from 'components/forms/button/button.component'
import type Dialog from 'components/containers/dialog/dialog.component'
import type { EventFor } from 'types/types/event-for.type'

interface UseDialogParams extends Pick<DialogProps, 'onYes' | 'onClose'> {
    /** ForwardedRef */
    forwardedRef: ForwardedRef<ElementRef<typeof Dialog>>
}

export interface UseDialogReturns {
    /** OnClose */
    onClose: NonNullable<ComponentProps<'button'>['onClick']>
    /** OnSubmit */
    onSubmit: NonNullable<ButtonProps['onClick']>
    /** DialogRef */
    dialogRef: RefObject<ElementRef<typeof Dialog>>
    /** IsLoading */
    isLoading: boolean
}

/**
 * Use dialog
 * @returns Dialog
 */
export function useDialog({ onYes, forwardedRef, onClose: onCloseProps }: UseDialogParams): UseDialogReturns {
    const [isLoading, setIsLoading] = useState(false)

    const dialogRef = useRef<ElementRef<typeof Dialog>>(null)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(forwardedRef, () => dialogRef.current!)

    const onClose = useCallback<UseDialogReturns['onClose']>(
        ev => {
            onCloseProps?.(ev)
            dialogRef.current?.close()
        },
        [onCloseProps],
    )

    const onSubmit = useCallback<UseDialogReturns['onSubmit']>(
        ev => {
            setIsLoading(true)
            void onYes?.(ev as EventFor<'button', 'onClick'>).finally(() => {
                setIsLoading(false)
            })
        },
        [onYes],
    )

    // Prevent Escape if loading
    const onKeyPressEscape = useCallback(
        (ev: KeyboardEvent) => {
            if (!ev.ctrlKey && !ev.altKey && !ev.shiftKey && isLoading) {
                ev.preventDefault()
            }
        },
        [isLoading],
    )

    useKeyPressEvent('Escape', onKeyPressEscape)

    /**
     * Check if scrolling to disable it if modal is open.
     *
     * This is a temporary solution, see {@link https://github.com/whatwg/html/issues/7732}
     */
    const onScroll = useCallback((ev: EventFor<'div', 'onScroll'>) => {
        if (dialogRef.current?.open) {
            ev.preventDefault()
        }
    }, [])

    useListener('scroll', onScroll as never, undefined, { passive: false })
    useListener('mousewheel', onScroll as never, undefined, { passive: false })
    useListener('touchmove', onScroll as never, undefined, { passive: false })

    return {
        onClose,
        onSubmit,
        dialogRef,
        isLoading,
    }
}
