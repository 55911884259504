import { notFound } from '@tanstack/react-router'
import Exception from 'requests/exception'
import useUserStore from 'stores/user.store'
import Scraper from 'types/consts/scraper.const'
import { ScrapersData } from 'types/data/scrapers.data'
import { NEW } from 'types/others'

const getSearch = (searchId: string) => {
    const { me } = useUserStore.getState()
    return {
        me,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        search: me!.searches.find(s => s._id === searchId),
    }
}

interface CheckSearch {
    /** Searchid */
    searchid: string
}

export const checkExistingSearch = ({ searchid }: CheckSearch) => {
    const { me, search } = getSearch(searchid)

    if (me && !search) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw notFound({ data: { exception: new Exception({ message: 'This search was not found', status: 404 }) } })
    }
}

export const checkExistingOrNewSearch = ({ searchid }: CheckSearch) => {
    if (searchid === NEW) {
        return
    }

    checkExistingSearch({ searchid })
}

interface CheckScraper extends CheckSearch {
    /** Scrapervalue */
    scrapervalue: string
}

export const checkExistingScraper = ({ searchid, scrapervalue }: CheckScraper) => {
    const { search } = getSearch(searchid)

    if (
        !Object.values(Scraper)
            .filter(scrapVal => !ScrapersData[scrapVal].isHidden?.(search))
            .includes(scrapervalue as never)
    ) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw notFound({ data: { exception: new Exception({ message: 'This scraper was not found', status: 404 }) } })
    }
}

export const checkExistingOrOptionalScraper = ({ searchid, scrapervalue }: CheckScraper) => {
    if (!scrapervalue) {
        return
    }

    checkExistingScraper({ searchid, scrapervalue })
}
