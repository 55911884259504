import { createWithEqualityFn as create } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'

export interface HomeState {
    /** Is editing  page */
    isEditing: boolean
    /** Are disable search visible  */
    areDisableSearchVisible: boolean
    /** SetIsEditing */
    setIsEditing: (isEditing: HomeState['isEditing']) => void
    /** SetAreDisableSearchVisible */
    setAreDisableSearchVisible: (areDisableSearchVisible: HomeState['areDisableSearchVisible']) => void
}

const useHomeStore = create<HomeState>()(
    set => ({
        isEditing: false,
        areDisableSearchVisible: false,
        setIsEditing: isEditing => {
            set({ isEditing })
        },
        setAreDisableSearchVisible: areDisableSearchVisible => {
            set({ areDisableSearchVisible })
        },
    }),
    shallow,
)

export default useHomeStore
