const NotificationPeriod = {
    NORMAL: 'normal',
    SLOW: 'slow',
    VERY_SLOW: 'very_slow',
} as const

export type NotificationPeriodType = typeof NotificationPeriod
export type NotificationPeriodKeys = keyof NotificationPeriodType
export type NotificationPeriodValues = NotificationPeriodType[NotificationPeriodKeys]

export default NotificationPeriod
