import CountryCode from 'types/consts/countries/country-code.const'
import type { CountryCodeKeys } from 'types/consts/countries/country-code.const'

/**
 * Know if flag emoji are visible on current navigator
 *
 * {@link https://stackoverflow.com/a/57789475/9678258}
 * @param value Emoji flag to test
 * @returns Is emoji visible
 */
function getIsFlagEmojiVisible(value = '🇫🇷'): boolean {
    const canvas = document.createElement('canvas')
    canvas.height = 10
    canvas.width = canvas.height * 2
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ctx = canvas.getContext('2d')!
    ctx.font = `${canvas.height}px Arial`
    ctx.fillText(value, 0, canvas.height)
    const { data } = ctx.getImageData(0, 0, canvas.width, canvas.height)
    let i = 0
    while (i < data.length) {
        if (data[i] !== data[i + 1] || data[i] !== data[i + 2]) {
            return true
        }
        i += 4
    }

    return false
}

/**  Is flag emoji proxy value */
let isFlagEmojiVisible: boolean | null = null

/**
 * Get country flag from code
 * @param countryCode countryCode
 * @param isForce isForce
 * @returns Emoji
 */
export default function getCountryFlagFromCode(countryCode: CountryCodeKeys, isForce = false): string | null {
    // If country is valid
    if (!Object.values(CountryCode).includes(countryCode.toUpperCase() as CountryCodeKeys)) {
        return null
    }

    /** Emoji generated */
    const value = countryCode.toUpperCase().replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt(0)))

    // Return emoji without check
    if (isForce) {
        return value
    }

    // If value is not set yet, run function
    if (isFlagEmojiVisible === null) {
        isFlagEmojiVisible = getIsFlagEmojiVisible()
    }

    // Flag emoji is visible
    if (isFlagEmojiVisible) {
        return value
    }

    return null
}
