const ItemCondition = {
    M: 'Mint',
    NM: 'Near Mint',
    VG: 'Very Good',
    G: 'Good',
    P: 'Poor',
    'N/A': 'N/A',
} as const

export default ItemCondition
