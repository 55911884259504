import variables from 'styles/exports/variables.module.scss'

/**
 * Get variable from SCSS
 * @param key Color key
 * @returns Variable
 */
export default function getVariable(
    key: 'tab-size' | 'tab-border-width' | 'header-height' | 'gap' | 'tablet' | 'desktop' | 'widescreen' | 'fullhd',
): string {
    return variables[key]
}
