/** When a query is cancelled: Chrome */
export const ABORTED_REQUEST_CHROME = 'The user aborted a request.'
/** When a query is cancelled: Firefox */
export const ABORTED_REQUEST_FIREFOX = 'The operation was aborted. '

/** Webauthn cancel message on Chrome */
export const WEBAUTHN_CANCEL_CHROME = 'The operation either timed out or was not allowed.'
/** Webauthn cancel message on Firefox */
export const WEBAUTHN_CANCEL_FIREFOX =
    'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.'
