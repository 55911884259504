import { IoDiscSharp } from 'react-icons/io5'
import { ImBooks } from 'react-icons/im'
import { MdVideogameAsset, MdMovie } from 'react-icons/md'
import { AiOutlineSearch } from 'react-icons/ai'
import SearchIcon from 'types/consts/search-icon.const'
import type { SearchIconValues } from 'types/consts/search-icon.const'
import type { IconType } from 'react-icons'

const SearchIconsData: {
    [key in SearchIconValues]: {
        /** Name */
        name: string
        /** Icon */
        icon: IconType
    }
} = {
    [SearchIcon.BOOK]: {
        name: 'Book',
        icon: ImBooks,
    },
    [SearchIcon.GAME]: {
        name: 'Game',
        icon: MdVideogameAsset,
    },
    [SearchIcon.MOVIE]: {
        name: 'Movie',
        icon: MdMovie,
    },
    [SearchIcon.MUSIC]: {
        name: 'Music',
        icon: IoDiscSharp,
    },
    [SearchIcon.OTHER]: {
        name: 'Other',
        icon: AiOutlineSearch,
    },
}

export default SearchIconsData
